<template>
  <div>
    <!-- <div class="field-label">
      上传身份证<span class="link" @click="showIdUploadExample">(查看示例)</span>：
    </div> -->
    <van-popup v-model="visible" round :close-on-click-overlay="false">
      <!-- 正面 -->
      <template v-if="step === 'Front'">
        <div class="id-card-uploader-item">
          <div class="id-card-uploader">
            <van-uploader
              :max-count="1"
              :after-read="uploadBackSideTibet"
              v-model="identityVerify.obverse"
            />
          </div>
          <div class="title">人像面</div>
        </div>
      </template>
      <!-- 背面 -->
      <template v-else-if="step === 'Back'">
        <div class="id-card-uploader-item">
          <div class="id-card-uploader">
            <van-uploader
              :max-count="1"
              :after-read="uploadReverseSideTibet"
              v-model="identityVerify.reverse"
            >
            </van-uploader>
          </div>
          <div class="title">国徽面</div>
        </div>
      </template>
      <!-- 人面像 -->
      <template v-else-if="step === 'User'">
        <div class="id-card-uploader-item">
          <div class="id-card-uploader">
            <van-uploader
              :after-read="uploadPersonImgTibet"
              :max-count="1"
              v-model="identityVerify.person"
            />
          </div>
          <div class="title">正面免冠照</div>
        </div>
      </template>
    </van-popup>
  </div>
</template>

<script>
import { apiUploadFront, apiUploadBack, apiUploadUser } from "@/api/api.js";
import Compressor from "compressorjs";

const imgCompressor = function (file, maxWidth = 1000, maxHeight = 1000, quality = 0.8) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      maxWidth,
      maxHeight,
      success: (result) => {
        // console.log(result);
        resolve(result);
      },
      error(err) {
        reject(err.message);
      },
    });
  });
};

const unrefData = {
  certificate: "",
};

export default {
  data() {
    return {
      visible: false,
      step: "Front", // Front | Back | User
      identityVerify: {
        obverse: [],
        reverse: [],
        person: [],
      },
      orderNo: "",
    };
  },
  methods: {
    nextStep() {
      switch (this.step) {
        case "Front": {
          this.step = "Back";
          break;
        }
        case "Back": {
          this.step = "User";
          break;
        }
        case "User": {
          this.$emit("ok", this.orderNo);
          this.$router.push(`/result/${this.orderNo}`);
          break;
        }
      }
    },
    open(certificate) {
      unrefData.certificate = certificate;
      this.step = "Front";
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    // 上传身份证
    async handleUploadIdCordTibet(file, api) {
      file.status = "uploading";
      file.message = "上传中";
      const formData = new FormData();
      const result = await imgCompressor(file.file);
      formData.append("file", result, result.name);
      const res = await api(formData, { certificate: unrefData.certificate });
      if (res) {
        if (res.code === 200) {
          file.status = "done";
          return Promise.resolve(res);
        }
        file.status = "failed";
        file.message = "识别失败";
        return Promise.reject();
      }
      file.status = "failed";
      file.message = "上传失败";
      return Promise.reject();
    },
    // 上传身份证正面
    uploadBackSideTibet(file) {
      this.handleUploadIdCordTibet(file, apiUploadFront).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.nextStep();
        }
      });
    },
    // 上传身份证背面
    uploadReverseSideTibet(file) {
      this.handleUploadIdCordTibet(file, apiUploadBack).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.nextStep();
        }
      });
    },
    // 上传半身像
    uploadPersonImgTibet(file) {
      this.handleUploadIdCordTibet(file, apiUploadUser).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.orderNo = res.result.orderNo;
          this.nextStep();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.id-card-uploader-item {
  width: 90vw;
  &:nth-of-type(1) .id-card-uploader {
    background-image: url("~@/assets/id-card-front.png");
  }
  &:nth-of-type(2) .id-card-uploader {
    background-image: url("~@/assets/id-card-back.png");
  }
  &:nth-of-type(3) .id-card-uploader {
    background-image: url("~@/assets/id-card-person.png");
  }
  .id-card-uploader {
    width: 320px;
    height: 208px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    padding: 0 0 20px;
  }
}
</style>
